.as-featured-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: var(--ra-spacing-6);
    max-width: 800px;
    margin-inline: auto;

    img {
      max-width: 200px;
      object-fit: contain;
    }
  }
}
